/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useRouter, Loading, SiteLink } from "../../../components";
import { useState, useEffect, Fragment } from "react";
import { useAuth } from "auth";
import env from "../../../env";
import style from "./style";
import { ViewPartial } from "../../../cms/pages/partials/viewPartial";
import { FaClipboardList, FaUserCheck, FaSignOutAlt } from "react-icons/fa";
import { useStore } from "../../../store";
import { WholesaleRegisterView } from "../register/view";
import { getIdToken } from "lib/auth/services/authHelper";
import utils from "../../../utils";

export function WholeSaleDashBoardView(props) {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const { dispatch } = useStore();
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState();
  //const [user, setUser] = useState();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      //console.log("await auth.loggedIn()", await auth.loggedIn());
      const _user = await auth.loggedIn();
      
      if (!_user) {
        router.push("/member-login");
        return;
      }

      const token = await getIdToken();
      const res = await fetch(
        env.apiBase + "/api/wholesale/getwholesaleuserinfo",
        {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }
      );
      const data = await res.json();
      if(data && data.statusCode && data.statusCode === 500){
        auth.signout().then((user) => {
          dispatch({ type: "SET_STATE", payload: { authCheck: false }});
          dispatch({ type: 'SET_STATE', payload: { cartInfo:0 }});
          dispatch({ type: 'SET_STATE', payload: { userFirstName : "" }});
          router.push("/member-login");
        }) 
        return;
      }

      setData(data);
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  function logOut(e) {
    e.preventDefault();
    e.stopPropagation();
    auth
      .signout()
      .then((user) => {
        dispatch({ type: "SET_STATE", payload: { authCheck: false }});
        dispatch({ type: 'SET_STATE', payload: { cartInfo:0 }});
        dispatch({ type: 'SET_STATE', payload: { userFirstName : "" }});
        router.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (loading) {
    return <LoadingView />;
  }

  return (
    <Fragment>
      <div className="dash_board_banner" css={style.dash_board_banner}></div>
      <div className="contentPanel" style={{backgroundImage:'url("/sites/anfc/media/misc/anfc-back.jpg")', backgroundSize : '100%', backgroundRepeat : 'repeat', backgroundPosition : '50%'}}>
        <div className="contentPanel__bg"></div>
        <div className="container">
          <div className="dash_board_menus" css={style.dash_board_menus}>
            <div
              className={`dash_board_menu${current === 1 ? " selected" : ""}`}
              css={style.dash_board_menu}
            >
              <div
                className="dash_board_menu_item"
                style={{
                  display: "inline-block",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}
                onClick={() => {
                  setCurrent(1);
                }}
              >
                <FaClipboardList /> Orders
              </div>
            </div>
            <div
              className={`dash_board_menu${current === 2 ? " selected" : ""}`}
              css={style.dash_board_menu}
            >
              <div
                className="dash_board_menu_item"
                style={{
                  display: "inline-block",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}
                onClick={() => {
                  setCurrent(2);
                }}
              >
                <FaUserCheck /> Account details
              </div>
            </div>
            <div className="dash_board_menu" css={style.dash_board_menu}>
              <div
                className="dash_board_menu_item"
                style={{
                  display: "inline-block",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}
                onClick={(e) => logOut(e)}
              >
                <FaSignOutAlt /> Log out
              </div>
            </div>
          </div>

          {current === 1 && <OrderListView data={data} />}
          {current === 2 && <AccountDetailsView data={data} />}
        </div>
      </div>
      <ViewPartial />
    </Fragment>
  );
}

function LoadingView() {
  return <Loading></Loading>;
}

function AccountDetailsView({ data }) {
  async function functionFrom(values) {
    const sendValues = {
      //uid: user.uid,
      firstName: values.firstName,
      lastName: values.lastName,
      businessPhone: values.businessPhone,
      mobilePhone: values.mobilePhone,
      businessName: values.businessName,

      abn: values.abn,
      acn: values.acn,

      businessCategory: values.businessCategory,
      estimatedSpend: values.estimatedSpend,

      billingInfo: values.billingInfo,
      shippingInfo: values.shippingInfo,
      useSameAddress: values.useSameAddress,
    };

    const token = await getIdToken();
    const res = await fetch(
      env.apiBase + "/api/wholesale/updateuseraccountdetails",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(sendValues),
      }
    );
    const _data = await res.json();
    if (
      _data &&
      _data.results &&
      _data.results.result &&
      _data.results.result === "successful"
    ) {
      window.location.reload();
    }
    return _data;
  }
  const formData = {
    ...data.wholeSaleRegister,
    billingAddress: { ...data.wholeSalAdrBilling },
    shippingAddress: { ...data.wholeSalAdrSelling },
    hideTermsAndConditions: true,
    checkReadOnly: false,
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <WholesaleRegisterView functionFrom={functionFrom} formData={formData} />
    </div>
  );
}

function OrderListView({ data }) {
  const orders = data.orders;
  // const orders = [
  //   {
  //     orderNo: "Test-V8720001",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V8720002",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V8720003",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V8720004",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V8720005",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V8720006",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V8720007",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V8720008",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V8720009",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V87200010",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V87200011",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V87200012",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  //   {
  //     orderNo: "Test-V87200013",
  //     totalAmount: 28.9,
  //     orderDate: "2023-11-27T11:08:27.022948+00:00",
  //   },
  // ];

  const [orderData, setOrderData] = useState();

  function addData() {
    const sliced = orders.slice(orderData.length, orderData.length + 10);
    sliced.forEach((el) => {
      orderData.push(el);
    });
    setOrderData([...orderData]);
  }

  useEffect(() => {
    if (orders.length > 0) {
      setOrderData(orders.slice(0, 10));
    } else {
      setOrderData([]);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginTop: "2rem" }}>
      {orderData && orderData.length === 0 && (
        <h3 style={{ textAlign: "center" }} css={style.h_3}>
          {" "}
          No orders
        </h3>
      )}

      <div className="order_list" css={style.order_list}>
        {orderData &&
          orderData.map((item, index) => (
            <OrderListItem key={index} item={item} />
          ))}
      </div>
      {orderData && orders.length !== orderData.length && (
        <div>
          <button className="btn btn-primary" onClick={addData}>
            MORE
          </button>
        </div>
      )}
    </div>
  );
}

function OrderListItem({ item }) {
  return (
    <div className="order_list_item" css={style.order_list_item}>
      <div className="order_list_item_container">
        <div className="order_list_item_order_no">
          <SiteLink to={`/wholesale-dashboard-order?o=${item.orderIdToken}`}>
            <span> Order Number: </span> <span>{item.orderNo}</span>
          </SiteLink>
        </div>
        <div>
          <span> Total Amount: </span>
          <span>
            <b>AU {utils.money.formatMoney(item.totalAmount)}</b>
          </span>
        </div>
        <div>
          <span> Order Date: </span>
          <span>{utils.date.formatYYYYMMDD(item.orderDate)}</span>
        </div>
      </div>
    </div>
  );
}
