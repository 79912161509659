/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
//import { MdAddShoppingCart } from "react-icons/md";
//import { useStore } from "../../store";
import { SearchButton } from "../searchButton/searchButton";
import { CartButton } from '../cartButton/cartButton';

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  //const { state } = useStore();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }

  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id="top">
        <BackButton />
        <SiteLink to="/" css={[style.logo, scrolled && style.logoScrolled]}>
          <img
            css={style.logoImg}
            src={
              scrolled && style.logoScrolled
                ? "/assets/logos/logo.png"
                : "/assets/logos/logo.png"
            }
            alt="logo"
          />
        </SiteLink>

        <SearchButton scrolled={scrolled} />

        {/* <div
          css={[
            style.shopping__cart,
            scrolled && style.shopping__cart__scrolled,
          ]}
          onClick={() => {
            props.openAddEnquiryForm();
          }}
        >
          <div style={{ position: "relative" }}>
            <MdAddShoppingCart />
            <div css={style.shopping__cart__num}>
              {state.addToQuote ? state.addToQuote : 0}
            </div>
          </div>

        </div> */}

        <CartButton />
        
        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
      {/* <div css={style.line__container}>
        <hr css={[style.hr, style.hr__1]} />
        <hr css={[style.hr, style.hr__2]} />
      </div> */}
    </Fragment>
  );
}