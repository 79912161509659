/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect, Fragment } from "react";
import {
  useRouter,
  Loading,
  ErrorMessage,
  SiteLink,
} from "../../../components";
import { useAuth } from "auth";
import { getIdToken } from "lib/auth/services/authHelper";
import env from "../../../env";
import style from "./style";
import { ViewPartial } from "../../../cms/pages/partials/viewPartial";
import utils from "../../../utils";

export function WholeSaleDashBoardOrderView(props) {
  //const { query } = useRouter();
  const router = useRouter();
  const o = router.query.o || "";
  const auth = useAuth();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      const _user = await auth.loggedIn();

      if (!_user) {
        router.push("/member-login");
        return;
      }

      const token = await getIdToken();
      const res = await fetch(
        env.apiBase + "/api/order/getpaidorderforwholesaleuser/" + o,
        {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }
      );
      const data = await res.json();

      if (data && data.statusCode && data.statusCode === 500) {
        setError(data.message);
      }
      setOrder(data);
      setLoading(false);
    };
    fetchData();
    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading></Loading>;
  }
  return <OrderViewItem order={order} error={error} />;
}

function OrderViewItem({ order, error }) {
  const orderItems = order.orderItems.filter((x) => x.orderItemType === 0);
  const taxLines = order.orderItems.filter((x) => x.orderItemType === 1);

  function itemAmount(orderItem) {
    return (
      (orderItem.unitPrice * 100 +
        (orderItem.addedUnitPrice ? orderItem.addedUnitPrice * 100 : 0) -
        (orderItem.discountUnitPrice ? orderItem.discountUnitPrice * 100 : 0)) /
      100
    );
  }

  return (
    <Fragment>
      <div
        className="order_detail_wholesale_user_banner"
        css={style.order_detail_wholesale_user_banner}
      />
      <div
        className="contentPanel"
        style={{
          backgroundImage: 'url("/sites/anfc/media/misc/anfc-back.jpg")',
          backgroundSize: "100%",
          backgroundRepeat: "repeat",
          backgroundPosition: "50%",
        }}
      >
        <div className="contentPanel__bg"></div>
        <div className="container">
          <div
            className="go_button"
            css={style.go_button}
            style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
          >
            <SiteLink style={{ fontWeight: "700" }} to="/wholesale-dashboard">
              Go Wholesale Dashboard
            </SiteLink>
          </div>

          <div
            className="order_detail_wholesale_user_info"
            css={style.order_detail_wholesale_user_info}
          >
            <div className="row">
              <div className="col-12">
                <div>
                  <label>Order Number:</label> <span>{order.orderNo}</span>
                </div>
                <div>
                  <label>Total Amount:</label>{" "}
                  <span>
                    <b>AU {utils.money.formatMoney(order.totalAmount)}</b>
                  </span>{" "}
                </div>
                <div>
                  <label>Order Date:</label>{" "}
                  <span>{utils.date.formatYYYYMMDD(order.orderDate)}</span>{" "}
                </div>
              </div>
            </div>

            <hr
              style={{
                width: "98%",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            />
            <h4
              style={{
                fontFamily: '"proxima-nova", sans-serif',
                marginTop: "0",
                marginBottom: "0",
                marginLeft: "10px",
              }}
            >
              ITEMS
            </h4>
            <div css={style.orderItems}>
              {orderItems.map((orderItem, i) => (
                <div className="row" key={orderItem.orderItemId}>
                  <div className="col-6">
                    <b
                      dangerouslySetInnerHTML={{ __html: orderItem.itemName }}
                    ></b>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: orderItem.itemDescHtml,
                      }}
                    ></div>
                  </div>
                  <div className="col-3">
                    <div className="price">
                      Unit Price:{" "}
                      {utils.money.formatMoney(itemAmount(orderItem))}
                    </div>
                  </div>
                  <div className="col-3">Qty: {orderItem.quantity}</div>
                </div>
              ))}

              <div className="row">
                <div className="col-9">Shipping Fee:</div>
                <div className="col-3">
                  <div>
                    <b>{utils.money.formatMoney(order.shippingAmount)}</b>
                  </div>
                </div>
              </div>

              {/* {taxLines.map((orderItem, index) => (
                <div key={orderItem.orderItemId} className="row">
                  <div className="col-9">Tax: {orderItem.itemName}</div>
                  <div className="col-3">
                    <div>
                      <b>{utils.money.formatMoney(orderItem.unitPrice)}</b>
                    </div>
                  </div>
                </div>
              ))} */}

              <div className="row">
                <div className="col-9"> GST: GST included in price </div>
              </div>

              <div
                className="row"
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <div className="col-12">
                  <div css={style.total}>
                    Total: AU {utils.money.formatMoney(order.totalAmount)}
                  </div>
                </div>
              </div>
            </div>

            <hr
              style={{
                width: "98%",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />

            <h4
              style={{
                fontFamily: '"proxima-nova", sans-serif',
                marginTop: "0",
                marginBottom: "0",
                marginLeft: "10px",
              }}
            >
              DELIVERY METHOD
            </h4>
            <div style={{ marginLeft: "10px" }}>{order.shippingMethod}</div>
            {order.shippingAddress && order.shippingMethod === "Standard" && (
              <Address addr={order.shippingAddress} />
            )}
            <Billing
              billingAddr={order.billingAddress}
              useSameAddress={order.useSameAddress}
            />
          </div>
          <ErrorMessage errors={error} />
        </div>
      </div>
      <ViewPartial />
    </Fragment>
  );
}

function Address({ addr }) {
  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <hr
        style={{
          width: "100%",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        }}
      />
      <h3
        style={{
          fontFamily: '"proxima-nova", sans-serif',
          marginTop: "0px",
          marginBottom: "0px",
        }}
      >
        {" "}
        SHIPPING ADDRESS{" "}
      </h3>
      <div>
        <label> Name: </label> <span>{addr.firstName}</span>{" "}
      </div>
      {/* <div>
        <label>Surame:</label> <span>{addr.surname}</span>{" "}
      </div> */}
      <div>
        <label>Address:</label>
        <span>{addr.street1}</span>
        <span style={{ marginLeft: "0.5rem" }}>{addr.street2}</span>
      </div>
      <div>
        <label>Suburb:</label> <span>{addr.suburb}</span>{" "}
      </div>
      <div>
        <label>State:</label> <span>{addr.state}</span>{" "}
      </div>
      <div>
        <label>Postcode:</label> <span>{addr.postcode}</span>{" "}
      </div>
      <div>
        <label>Email Address:</label> <span>{addr.email}</span>{" "}
      </div>
      <div>
        <label>Mobile:</label> <span>{addr.mobile}</span>{" "}
      </div>
    </div>
  );
}

function Billing(props) {
  const billingAddr = props.billingAddr;
  const useSameAddress = props.useSameAddress;
  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <hr
        style={{
          width: "100%",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        }}
      />
      <h3 style={{ fontFamily: '"proxima-nova", sans-serif' }}>
        {" "}
        BILLING ADDRESS{" "}
      </h3>
      {useSameAddress === true && <div> Use the same address </div>}
      {billingAddr && useSameAddress === false && (
        <Fragment>
          <div>
            <label> Name:</label> <span>{billingAddr.firstName}</span>{" "}
          </div>
          {/* <div>
            <label>Surame:</label> <span>{billingAddr.surname}</span>{" "}
          </div> */}
          <div>
            <label>Address:</label>
            <span> {billingAddr.street1} </span>
            <span style={{ marginLeft: "0.5rem" }}>
              {" "}
              {billingAddr.street2}{" "}
            </span>
          </div>
          <div>
            <label>Suburb:</label> <span>{billingAddr.suburb}</span>{" "}
          </div>
          <div>
            <label>State:</label> <span>{billingAddr.state}</span>{" "}
          </div>
          <div>
            <label>Postcode:</label> <span>{billingAddr.postcode}</span>{" "}
          </div>
          <div>
            <label>Email Address:</label> <span>{billingAddr.email}</span>{" "}
          </div>
          <div>
            <label>Mobile:</label> <span>{billingAddr.mobile}</span>{" "}
          </div>
        </Fragment>
      )}
    </div>
  );
}
