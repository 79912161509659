/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState, useEffect } from 'react';
import env from '../../../env';
import { Loading, ErrorPopup, usePost } from '../../../components';
import { useForm } from '../../../form';
import { ProductTile } from './productTile';
import { tileListStyle } from './productTileStyle';
import { getCategoryListModel } from './categoryListModel';
import { useAuth } from "auth";

export function ProductItems({ categories, mainCategory }) {
  const post = usePost();
  React.useEffect(() => {
    post.send(env.apiBase + '/api/product/getProducts', {
      categories: categories,
    });
    // eslint-disable-next-line
  }, [categories]);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />;
  }

  const data = post.response;
  console.log(
    '%c [ mainCategory ]-27',
    'font-size:13px; background:pink; color:#bf2c9f;',
    mainCategory
  );
  const mainCategory_name = data.categories.filter(
    (category) => category.categoryId === mainCategory
  )[0].categoryName;

  data.selectedCategories = [];
  data.categories.map((c) => {
    categories.split(',').map((s) => {
      // eslint-disable-next-line
      if (c.categoryId == s) {
        data.selectedCategories.push(c);
      }
      return null;
    });
    return null;
  });
  
  console.log('product List', data);

  return(
    <CheckAuthView data = {data} categories = {categories} mainCategory = {mainCategory} mainCategory_name = {mainCategory_name} />
  )

}

function CheckAuthView({data, categories, mainCategory, mainCategory_name}){
  const auth = useAuth();
  const [checkAuth, setCheckAuth ] = useState(null);
  useEffect(() => {
    const fetch = async () => {
      if (await auth.loggedIn()){
        setCheckAuth(true);
      }
      else{
        setCheckAuth(false);
      }
    };
    fetch();

    // eslint-disable-next-line
  }, []);

  if(checkAuth === null){
    return(
      <Loading />
    )
  }
  
  return data.items && data.items.length > 0 ? (
    <Items
      items={data.items}
      categories={data.selectedCategories}
      defaultCategories={categories}
      mainCategory={mainCategory}
      mainCategory_name={mainCategory_name}
      checkMember = {data.checkMember}
      checkAuth = {checkAuth}
    />
  ) : (
    <div>No products</div>
  );

}

function Items({
  items,
  categories,
  defaultCategories,
  mainCategory,
  mainCategory_name,
  checkMember,
  checkAuth
}) {
  const s = tileListStyle;
  const defaultCategoriesArray =
    defaultCategories && defaultCategories !== ''
      ? defaultCategories.split(',')
      : [];
  const model = getCategoryListModel(
    categories,
    defaultCategoriesArray,
    mainCategory
  );

  const categoryListForm = useForm(model, {
    usePlaceholder: true,
    onControlChanged: orderItemChanged,
  });

  const [showProducts, setShowProducts] = useState(() => {
    if (defaultCategoriesArray.length === 1) {
      let _items = [];
      let _items2 = JSON.parse(JSON.stringify(items));
      _items = _items2.filter((item) => {
        for (let i in item.productCategories) {
          if (
            item.productCategories[i].categoryId === defaultCategoriesArray[0]
          ) {
            item.seqInCategory = item.productCategories[i].seq;
            return true;
          }
        }
        return false;
      });
      sortProudcts(_items);
      return _items;
    }
    return items;
  });

  function orderItemChanged(e, control) {
    if (control && control.name === 'categoryList') {
      console.log('controll value', control.value);
      let _items = [];
      if (control.value && control.value !== '') {
        _items = items.filter((item) => {
          if (control.value === '_favourites_') {
            if (localStorage.favorites !== '') {
              if (localStorage.favorites.includes(item.productId)) {
                return true;
              }
            }
          } else if (control.value === mainCategory_name) {
            for (let i in item.productCategories) {
              item.seqInCategory = item.productCategories[i].seq;
            }
            return true;
          } else {
            for (let i in item.productCategories) {
              if (item.productCategories[i].categoryId === control.value) {
                item.seqInCategory = item.productCategories[i].seq;
                return true;
              }
            }
          }
          return false;
        });
      } else {
        _items = items;
      }
      //sort
      sortProudcts(_items);
      setShowProducts(_items);
    }
  }

  const render = (name) => categoryListForm.renderControl(name, null);
  const _checkAuth = (checkAuth === true && checkMember === true) ? true : false; 

  return (
    <Fragment>
      <div css={s.categoryList}>{render('categoryList')}</div>
      <div css={s.tiles}>
        {/* {items.map((item, index) => <ProductTile key={index} product={item} />)} */}
        {showProducts.length > 0 ? (
          showProducts.map((item, index) => (
            <ProductTile key={index} product={item} checkAuth = {_checkAuth} />
          ))
        ) : (
          <div style={{ margin: 'auto' }}> No product </div>
        )}
      </div>
    </Fragment>
  );
}

function sortProudcts(items) {
  items.sort((a, b) => {
    return a.seqInCategory - b.seqInCategory;
  });
}
