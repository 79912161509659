import { mq, variables } from "../../../cssInJs";

export default {
  order_detail_wholesale_user_banner: mq({
    backgroundImage:
      'url("/sites/anfc/media/misc/native-food-in-jars.jpg")',
    height: ["92px", null, "207px"],
  }),

  order_detail_wholesale_user_info: {
    backgroundColor: "white",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    ".row": {
      marginLeft: "0px",
      marginRight: "0px",
    },
    label: {
      marginBottom: "0rem",
    },
  },

  go_button : {
    transition: 'all 0.2s ease-out',
    'a:hover' : {
        color: variables.colorLightGreen
    }
  }
  
};