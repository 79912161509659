/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment, useState } from "react";
import { getWholesaleRegisterModel } from "./wholesaleRegisterModel";
import { useForm } from "../../../form";
import style from "./wholesaleRegisterStyle";
import { Button, ErrorMessage } from "../../../components";

export function WholesaleRegisterView({ functionFrom, formData }) {
  const model = getWholesaleRegisterModel({ ...formData });
  const form = useForm(model, {
    usePlaceholder: false,
    onControlChanged: orderItemChanged,
  });
  //const post = usePost();
  const render = (name) => form.renderControl(name, null);
  const [sendingStatus, setSendingStatus] = useState(null);
  const [error, setError] = useState("");
  const val = form.getValue();
  const billingInfo = form.model.find("billingAddress");
  const shippingInfo = form.model.find("shippingAddress");
  const checkToUpdateForm = form.model.find("checkToUpdateForm").value;
  
  if (shippingInfo) {
    shippingInfo.disableValidators = val.useSameAddress;
  }
  billingInfo.disableValidators = false;

  function orderItemChanged(e, control) {
    if (control && control.name === "checkToUpdateForm") {
      const value = control.value;
      const model = getWholesaleRegisterModel({
        ...form.getValue(),
        checkReadOnly: !value,
        hideTermsAndConditions : (formData && formData.hideTermsAndConditions) ? formData.hideTermsAndConditions : false
      });
      form.reset(model);
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    // functionFrom({test : "abcccc"}).then(() => {
    //     console.log("555555555555555");
    // });

    form.validateForm(e, () => {
      setSendingStatus("pending");
      const value = { ...form.getValue() };
      functionFrom(value).then((res) => {
        
        if(res && res.errors && res.errors.length > 0){
          setError(res.errors[0].errorMessage);
        } 
        setSendingStatus(null);
      });
    });
  }

  return (
    <form
      className="wholesale_register_form"
      css={style.wholesale_register_form}
    >
      <div className="form-row">
        <div className="col-md-3 mb-2">{render("firstName")}</div>
        <div className="col-md-3 mb-2">{render("lastName")}</div> 
        {formData.hideTermsAndConditions &&
        formData.hideTermsAndConditions === true ? null : (
          <div className="col-md-3 mb-2">{render("email")}</div>
        )} 
        {formData.hideTermsAndConditions &&
        formData.hideTermsAndConditions === true ? null : (
          <div className="col-md-3 mb-2">{render("password")}</div>
        )}
      </div>

      <div className="form-row">
        <div className="col-md-6 mb-2">{render("businessPhone")}</div>
        <div className="col-md-6 mb-2">{render("mobilePhone")}</div>
      </div>

      <h3 css={style.h_3}>Business Details</h3>
      <hr />

      <div className="form-row">
        <div className="col-md-4 mb-2">{render("businessName")}</div>
        <div className="col-md-4 mb-2">{render("abn")}</div>
        <div className="col-md-4 mb-2">{render("acn")}</div>
      </div>

      <Address form={form} addressModel={billingInfo} />

      <h4>Shipping/Postal Address:</h4>
      <div className="form-row">
        <div className="col-md-12 mb-2">{render("useSameAddress")}</div>
      </div>

      {!val.useSameAddress && (
        <Address form={form} addressModel={shippingInfo} />
      )}

      <div className="form-row">
        <div className="col-md-6 mb-2">{render("businessCategory")}</div>
        <div className="col-md-6 mb-2">{render("estimatedSpend")}</div>
      </div>

      {formData.hideTermsAndConditions &&
      formData.hideTermsAndConditions === true ? null : (
        <Fragment>
          <h4> Terms and conditions </h4>
          <div className="form-row">
            <div className="col-md-12 mb-2">{render("checkConditions")}</div>
          </div>
        </Fragment>
      )}

      {/* <div className="form-row">
        <div className="col-md-12 mb-2">{render("checkToUpdateForm")}</div>
      </div> */}

      <div className="form-row">
        <div className="col-md-12 mb-2">
          <div className="actions" style={{ textAlign: "left" }}>

            {checkToUpdateForm === true && (
              <Button status={sendingStatus} onClick={onSubmit}>
                Save Changes
              </Button>
            )}

            <ErrorMessage
              errors={form.errors}
              summaryMessage="Please review the errors."
            />
            <ErrorMessage errors={error} />
            {/* {post.done() && (
                <span
                  style={{
                    marginLeft: "1.5rem",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  Successfully sent!
                </span>
              )} */}
          </div>
        </div>
      </div>
    </form>
  );
}

function Address({ form, addressModel }) {
  const render = (name) => form.renderControl(name, addressModel);
  return (
    <Fragment>
      <div className="form-row">
        <div className="col-md-4 mb-2">{render("addressFirstName")}</div>
        <div className="col-md-4 mb-2">{render("addressLastName")}</div>
        <div className="col-md-4 mb-2">{render("addressEmail")}</div>
      </div>

      <div className="form-row">
        <div className="col-md-12 mb-2">{render("address")}</div>
      </div>

      <div className="form-row">
        <div className="col-md-3 mb-2">{render("city")}</div>
        <div className="col-md-3 mb-2">{render("state")}</div>
        <div className="col-md-3 mb-2">{render("postalCode")}</div>
        <div className="col-md-3 mb-2">{render("country")}</div>
      </div>
    </Fragment>
  );
}
