/** @jsx jsx */
import { jsx } from '@emotion/core'
//import React from 'react';
import { Item } from '../../items/item';
import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import Slider from "react-slick";

export function BannerPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const overlayColor = cmsUtils.payload(item, 'BgColor') || 'transparent';
  const overlayOpacity = cmsUtils.payload(item, 'OverlayOpacity') || '1.0';
  const cmsOption = props.cmsOption ? {...props.cmsOption, overlayColor : overlayColor, overlayOpacity : overlayOpacity } : {};

  const slides = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption} overlayColor = {overlayColor} overlayOpacity = {overlayOpacity}></Item>
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const cssClass = utils.classNames('cms_item', 'bannerPanel', `bannerWrapperSize--${heightSet}`, item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <Slider {...settings} >
        {slides}
      </Slider>
    </div>
  );
}