//import { variables, mq } from 'cssInJs';
import { rgba } from 'polished';

export function getStyle({overlayColor, overlayOpacity, vAlign}){
    return{
        single__banner__bg : {
            '&:before' : {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top : '0',
                backgroundColor: overlayColor === 'transparent' ? 'transparent' : rgba(overlayColor, parseFloat(overlayOpacity))
                //backgroundColor: rgba(overlayColor, parseFloat(overlayOpacity))
            },
            backgroundPosition: `50% ${vAlign === 'center' ? '50%' : vAlign}`
        }
    }
}