//import { mq } from '../../../../cssInJs';

export default {
    member_login : {
        
    },

    logged_in_text : {
        
    },

    form : {
        //maxWidth: '600px'
    },
    button : {
        //transition: 'all 0.2s ease-out',
        '&:hover' : {
            color: '#8d2b00',
            //fontWeight: '700'
        }
    }
}