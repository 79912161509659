import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useRouter } from "./components";
import { StoreProvider } from "./store";
import { ViewPage } from "./cms/pages/viewPage/viewPage";
import { EditPage } from "./cms/pages/editPage/editPage";
import { TopHeader } from "./layout/topHeader/topHeader";
// import { Footer } from './layout/footer/footer';
import { BottomDock } from "./layout/bottomDock/bottomDock";
// import { BlogView } from './site/blog/blogView';
import { AppVersionPage } from "./components";
import { MediaQueryStatus } from "./components";
import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from "react-helmet-async";
//import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { AppRouteChanged } from "./AppRouteChanged";
import AOS from "aos";
import { ProvideAuth, useAuth } from "auth";

//For ECM
import { ProductView } from "./site/ecm/product/productView";
import { EnquiryFormView } from "./site/ecm/product/enquiryFormView";
import { OrderDetails } from "./site/ecm/orderDetails/orderDetails";
import { useStore } from "./store";
import { createPortal } from "react-dom";
import { WholeSaleDashBoardView } from "./site/wholeSale/dashboard/view";
import { WholeSaleDashBoardOrderView } from "./site/wholeSale/orderView/view";
import { ForgotPassword } from "./auth/controls/forgotPassword/forgotPassword";
import { ResetPassword } from "./auth/controls/resetPassword/resetPassword";
import env from "env";
import { getIdToken } from "lib/auth/services/authHelper";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

function AppLayout(props) {
  const { pathname } = useRouter();
  const auth = useAuth();

  //For ECM
  const storeContext = useRef(useStore());
  const [openPopup, setOpenPopup] = useState(false);

  //const [scrolled, setScrolled] = useState(false)
  //const path = usePath();
  //const routeResult = useRoutes(routes);

  // const parallax = useParallax();
  // //console.log(path, routeResult);
  // const routeChanged = (path) => {
  //   //console.log(path);
  //   parallax.reset();
  // }

  // useScrollPosition(({ prevPos, currPos }) => {
  //   //console.log(currPos.x)
  //   //console.log(currPos.y);
  //   if (currPos.y > 90) {
  //     document.querySelector('body').classList.add('scrolled')
  //   } else {
  //     document.querySelector('body').classList.remove('scrolled')
  //   }
  //   //setScrolled(currPos.y > 90)
  // }, [], null, true)

  //const pageChanged = useCallback(path => routeChanged(path), [])
  //useLocationChange((path) => { routeChanged(path, history)})
  //useLocationChange(path => routeChanged(path))

  //const pathFiltered = (pathname || '').toLocaleLowerCase();
  let specialClassName = "";
  //  if (pathFiltered.indexOf('/digital-sensor') >= 0) specialClassName = 'digital-sensor-page';
  //  else if (pathFiltered === '/brilliance') specialClassName = 'brilliance-page';

  function openAddEnquiryForm() {
    setOpenPopup(true);
    document.body.style.overflow = "hidden";
  }

  function closeAddEnquiryForm() {
    setOpenPopup(false);
    document.body.style.overflow = "unset";
  }
  
  useEffect(() => {
    //console.log("----stg----");

    if (!localStorage.favorites) {
      localStorage.setItem("favorites", "");
    }

    AOS.init({
      duration: 1200,
      //delay:400,
      //once:true
    });

    // For ECM
    // fetch(env.apiBase + "/api/cart/getcart/" + env.getDevCartId())
    //   .then((res) => res.json())
    //   .then((res) => {
    //     const data = res;
    //     if (data) {
    //       storeContext.current.dispatch({
    //         type: "SET_STATE",
    //         payload: {
    //           cartInfo:
    //             data.cartInfo.totalQuantity === 0
    //               ? 0
    //               : data.cartInfo.totalQuantity - 1,
    //         },
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     storeContext.current.dispatch({
    //       type: "SET_STATE",
    //       payload: { cartInfo: 0 },
    //     });
    //   });

    // let cookieValue = document.cookie
    //   .split("; ")
    //   .find((row) => row.startsWith("add_to_quote="))
    //   ?.split("=")[1];
    // if (cookieValue && cookieValue !== "") {
    //   const _value = cookieValue.split("|");
    //   storeContext.current.dispatch({
    //     type: "SET_STATE",
    //     payload: { addToQuote: _value.length },
    //   });
    // }
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      let checkAuth = false;

      if (await auth.loggedIn()) {
        checkAuth = true;
      }

      // if (checkAuth) {
      //   const token = await getIdToken();

      //   const res = await fetch(env.apiBase + "/api/profile/getuserprofilev3", {
      //     headers: { Authorization: `Bearer ${token}` },
      //     signal: signal,
      //   });
      //   const data = await res.json();
      //   if (
      //     data &&
      //     data.results &&
      //     data.results.user &&
      //     data.results.user.firstName
      //   ) {
      //     storeContext.current.dispatch({
      //       type: "SET_STATE",
      //       payload: { userFirstName: data.results.user.firstName },
      //     });
      //   } else {
      //     storeContext.current.dispatch({
      //       type: "SET_STATE",
      //       payload: { userFirstName: "" },
      //     });
      //   }
      // } else {
      //   storeContext.current.dispatch({
      //     type: "SET_STATE",
      //     payload: { userFirstName: "" },
      //   });
      // }

      const token = await getIdToken();
      const res = await fetch(env.apiBase + "/api/cart/getcart", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        signal: signal,
        method: "post",
        body: JSON.stringify({ cartId: env.getDevCartId(), auth: checkAuth })
        
      });

      const data = await res.json();
      
      if (data && data.cartInfo) {
        storeContext.current.dispatch({
          type: "SET_STATE",
          payload: {
            cartInfo:
              data.cartInfo.totalQuantity === 0
                ? 0
                : data.cartInfo.totalQuantity - 1,
          },
        });
      }

      if (data && data.wholeSaleRegister) {
        storeContext.current.dispatch({
          type: "SET_STATE",
          payload: { userFirstName: data.wholeSaleRegister.businessName },
        });
      } else {
        storeContext.current.dispatch({
          type: "SET_STATE",
          payload: { userFirstName: "" },
        });
      }

      storeContext.current.dispatch({
        type: "SET_STATE",
        payload: { authCheck: checkAuth },
      });

    };

    fetchData();
    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`body-content ${
        pathname === "/" ? "homepage" : "subpage"
      } ${specialClassName}`}
    >
      <AppRouteChanged />
      <TopHeader openAddEnquiryForm={openAddEnquiryForm}></TopHeader>
      <div className="main-content">
        <div className="app-content">
          <Switch>
            <Route path="/ver" children={<AppVersionPage />} />
            <Route path="/cms/page/render/:id" children={<EditPage />} />
            {/* <Route path="/blog/:id" children={<BlogView />} /> */}

            <Route path="/admin">
              <Redirect to="/admin" />
            </Route>

            <Route path="/order-thankyou" children={<OrderDetails />} />

            <Route
              path="/product/:slug"
              children={<ProductView openAddEnquiryForm={openAddEnquiryForm} />} ///member/
            />

            <Route
              path="/wholesale-dashboard"
              children={<WholeSaleDashBoardView />}
            />

            <Route
              path="/wholesale-dashboard-order"
              children={<WholeSaleDashBoardOrderView />}
            />

            <Route
              path="/member-forgot-password"
              children={<ForgotPassword />}
            />

            <Route path="/member-reset-password" children={<ResetPassword />} />

            <Route path="/">
              <ViewPage />
            </Route>
          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      {/* <Footer></Footer> */}
      <BottomDock></BottomDock>
      <MediaQueryStatus />
      <CommonVideoPopup />

      <Portal>
        {openPopup && (
          <EnquiryFormView closeAddEnquiryForm={closeAddEnquiryForm} />
        )}
      </Portal>
    </div>
  );
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
        <ProvideAuth>
          <Router>
            <AppLayout />
          </Router>
        </ProvideAuth>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;
