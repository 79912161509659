import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';

export function BlogPosts(props) {

  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const posts = getPages(cmsUtils.payload(item, 'BlogPosts'));
  const numOfTileLines = parseInt(cmsUtils.payload(item, 'NumOfTileLines', '2'));
  //console.log(numOfPost, parseInt(numOfPost));
  //console.log(posts);
  const bp = utils.ui.getCurrentBreakpoint();
  const tilesPerLine = { xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }
  const numOfPost = numOfTileLines * (tilesPerLine[bp] || 3);
  const step = numOfPost;

  const [tilesToShow, setTilesToShow] = React.useState(numOfPost);

  const displayingPosts = posts.length > tilesToShow ? posts.slice(0, tilesToShow) : posts;
  const showMore = posts.length > displayingPosts.length;

  const pageTileElts = displayingPosts.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  );

  const cssClass = utils.classNames('cms_item', 'blogTiles tiles', item.cssClass || item.anchorName || '');

  return (
    <>
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        {pageTileElts}
      </div>
      {showMore && (<div className="blogTiles__loadMore">
        <span className="thinBtn" onClick={(e) => { setTilesToShow(tilesToShow + step) }}>LOAD MORE</span></div>)}
    </>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  //const postDate = utils.date.formatDDMMYYYY(page.postDate);

  return (
    <div css={style.pageTile__small} className={`pageTile__small ${props.useSlider}`}>
      <SiteLink className="pageTile__small__content" css={style.pageTile__small__content} to={disableLink ? '' : page.pageUrl}>

        <div css={style.pageTile__small__wrapper} className='pageTile__small__wrapper'>
          
          <div style={{ overflow: 'hidden' }}>
            <div className="pageTile__small__bg" css={style.pageTile__small__bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
          </div>

          <div className="pageTile__big__title" css={style.pageTile__small__title}>
            <div className="pageTile__big__inner__title">
              {page.pageTitle}
            </div>
          </div>

          <div className="pageTile__small__des" css={style.pageTile__small__des}>
            <div className="pageTile__small__inner__des">
              {page.tileDesc}
            </div>
          </div>

          <div css={style.pageTile__small__button} className="pageTile__small__button">
            <button className='btn btn-primary'>
              READ MORE
            </button>
          </div>

        </div>
      </SiteLink>
    </div>
  )
}