import { mq, variables } from "../../../cssInJs"

export default {
    wholesale_register_form : {
        'label' : mq({
            fontSize: ['1rem',null,'0.9rem']
        }),
        'button' : {
            backgroundColor: '#8d2b01',
            borderColor: '#8d2b01',
            '&:hover' : {
                backgroundColor: '#5a1b00',
                borderColor: '#5a1b00',
            }
        }
    },
    h_3 : {
        fontFamily: variables.familyBase
    }
}