import { fb } from '../../../form';

export function getOrderItemFormData(cart) {
  const model = fb.group({
    items: fb.array([]),
  });

  const itemArray = model.find('items').controls;
  (cart.orderItems || []).forEach(item => {
    itemArray.push(orderItemData(item))
  })
  return model;
}


export function orderItemData(item) {
  return fb.group({
    quantity: [item.quantity || '', [], { label: null, type: 'number', attrs : {min : 1} }]
  })
}

// addedUnitPrice: 0
// discountUnitPrice: 0
// extSerialized: null
// itemDesc: ""
// itemDescHtml: ""
// itemName: "Donation"
// orderItemDescriptions: []
// orderItemId: "031283cd-dbf2-4d62-aaf7-4134231f27a6"
// orderItemOptions: []
// productCode: "Donation"
// productId: "dbdc9aad-16ab-47a0-97c7-ac2c13cf01a6"
// quantity: 1
// shippingFeeType: 1
// shppingFee: 0
// subTitle: null
// taxType: 2
// thumbnailUrl: null
// unitPrice: 0