/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './productViewStyle';
import { getEnquiryFormModel } from './enquiryFormModel';
import { Button, ErrorMessage, usePost } from '../../../components';
import { useForm } from '../../../form';
import { useState } from 'react';
import { useStore } from '../../../store';
import env from '../../../env';

export function EnquiryFormView(props) {

    const enquiryFormModel = getEnquiryFormModel({});
    const enquiryForm = useForm(enquiryFormModel, { usePlaceholder: true });
    const enquiryFormRender = (name) => enquiryForm.renderControl(name, null);
    const post = usePost();
    const { dispatch } = useStore();
    const [enquiryItems, setEnquiryItems] = useState(() => {
        const _result = [];
        let cookieValue = document.cookie.split("; ").find((row) => row.startsWith("add_to_quote="))?.split("=")[1];
        if (cookieValue && cookieValue !== '') {
            const _value = cookieValue.split("|");
            _value.forEach(el => {
                const _item = el.split("::");
                _result.push({
                    productId: _item[0],
                    productImg: _item[1],
                    productName: _item[2],
                    productVal: _item[3],
                    uniqueId: _item[4],
                    enquiryFormQty: _item[5]
                });
            });
        }

        console.log("_result", _result);

        return _result;
    });

    const [sendingStatus, setSendingStatus] = useState(null);

    function onSubmit(e) {
        enquiryForm.validateForm(e, () => {
            const value = { ...enquiryForm.getValue(), enquiryItems: enquiryItems, emailReceiver: '' };

            var formData = new FormData();
            formData.append('jsonData', JSON.stringify(value));
            post.sendFormData(env.apiBase + "/api/cart/sendenquire", formData);
            setSendingStatus('pending');

        });
    }

    function removeEnquiryItem(uniqueId) {
        const _fi = enquiryItems.filter((x) => {
            return x.uniqueId !== uniqueId;
        });

        dispatch({ type: 'SET_STATE', payload: { addToQuote: _fi.length } });

        let _cookie = "";
        for (let i in _fi) {
            if (i > 0) {
                _cookie = _cookie + "|";
            }
            _cookie = _fi[i].productId + ":" + _fi[i].productImg + ":" + _fi[i].productName;
        }
        document.cookie = `add_to_quote=${_cookie}; expires=Session; path=/`;
        setEnquiryItems(_fi);
    }

    if (post.done() && sendingStatus === 'pending' && !post.hasErrors()) { 

        setTimeout(() => {
            dispatch({ type: 'SET_STATE', payload: { addToQuote:  0 } });
            props.closeAddEnquiryForm();
        }, 500);

        document.cookie = `add_to_quote=${''}; expires=Session; path=/`;
        document.body.style.overflow = "unset";
        post.reset();
    }

    return (
        <div className="product__enquiry__form__container" css={style.enquiryFormContainer}>
            <form className="contactForm">
                <div className="closeButton" onClick={() => {
                    props.closeAddEnquiryForm();
                }}>
                    CLOSE
                </div>

                <div className="enquireNowText">
                    {/* Enquire  */}
                </div>

                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <div className='product__enquiry__form__item' css={style.product__enquiry__form__item}>
                            {
                                enquiryItems.map((item, index) => {
                                    return (
                                        <div key={index} className='product__enquiry__form__item__1' css={style.product__enquiry__form__item__1}>
                                            <div className='product__enquiry__form__item__1__img'>
                                                <img src={item.productImg} alt='product img' style={{ width: '100%' }} />
                                            </div>
                                            <div className='product__enquiry__form__item__1__titile'>
                                                <div>
                                                    {item.productName}
                                                </div>

                                                <div style={{ marginTop: '0.2rem', fontSize: '0.6rem' }}>
                                                    {item.productVal}
                                                </div>
                                                <div style={{ marginTop: '0.2rem', fontSize: '0.6rem' }}>
                                                    Qty : {item.enquiryFormQty}
                                                </div>
                                            </div>
                                            <div className='product__enquiry__form__item__1__button'>
                                                <Button onClick={() => {
                                                    removeEnquiryItem(item.uniqueId);
                                                }}> Remove </Button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>

                        <div className="form-row">
                            <div className="col-12 mb-2">{enquiryFormRender('name')}</div>
                            <div className="col-12 mb-2">{enquiryFormRender('phone')}</div>
                            <div className="col-12 mb-2">{enquiryFormRender('email')}</div>
                        </div>

                        <div className="form-row">
                            <div className="col-12 mb-2">{enquiryFormRender('enquiry')}</div>
                        </div>

                        <div className="form-row">
                            <div className="col-12 mb-2">
                                <div className="actions" style={{ textAlign: 'left' }}>
                                    {/* onClick={onSubmit} status={post.status} */}
                                    <Button onClick={onSubmit} status={post.status} disabled={post.sending()} > SEND ENQUIRY </Button>
                                    <ErrorMessage errors={post.errors} />
                                    {post.status === 'done' && 'Successfully sent!'}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </form>

        </div>
    )

}