import { rgba } from 'polished';

export function getStyle({overlayColor, overlayOpacity}){
    return{
        single__banner__bg : {
            '&:before' : {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top : '0',
                backgroundColor: overlayColor === 'transparent' ? 'transparent' : rgba(overlayColor, parseFloat(overlayOpacity)),
                zIndex: '4'
                //backgroundColor: rgba(overlayColor, parseFloat(overlayOpacity))
            }
        }
    }
}