import { mq, variables } from '../../../cssInJs';

// export default {
//     pageTiles__big: {
//         display: 'flex',
//         justifyContent: 'flex-start',
//         flexWrap: 'wrap',
//         margin: '2rem -10px',
//         overflow: 'hidden'
//     },
//     pageTile__big: mq({
//         flex: ['1 0 100%',null,'1 0 25%'],
//         maxWidth:['100%', null, '25%'],
//         padding: '10px',
//     }),
//     pageTile__big__content : {
//         '&:hover' : {
//             textDecoration: 'none',
//             '.pageTile__big__bg' : {
//                 transform : 'scale(1.05)'
//             }
//         }
//     },
//     pageTile__big__wrapper : {
//         boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.25)',
//         padding : '0.4rem',
//         overflow: 'hidden'
//     },
//     pageTile__big__bg : {
//         paddingTop: '100%',
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         backgroundRepeat: 'no-repeat',
//         transition:'all .2s'
//     },
//     pageTile__big__title : {
//         textAlign: 'center',
//         fontSize: '36px',
//         color: variables.fontColor,
//         marginTop: '0.5rem'
//     },
//     pageTile__big__middle__text : {
//         color: variables.fontColor,
//         textAlign: 'center',
//         marginTop: '0.5rem'
//     },
//     pageTile__big__button : {
//         textAlign: 'center',
//         marginTop: '0.5rem',
//         // 'button' : {
//         //     backgroundColor: variables.colorGreen,
//         //     color : 'white',
//         //     borderColor: variables.colorGreen
//         // }
//     }
// }

export default {
    pageTiles__small: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden',
        '&.slider' : {
            display : 'block',
            overflow: 'unset',
            '.slick-prev:before, .slick-next:before' : {
                color: 'black'
            },
            '.slick-prev' : mq({
                left: ['0px',null,'-22px']
            }),
            '.slick-next' : mq({
                right: ['0px',null,'-22px']
            })
        } 
    },
    pageTile__small: mq({
        flex: ['1 0 100%', null, '1 0 50%', '1 0 33.3%', '1 0 20%'],
        maxWidth: ['100%', null, '50%', '33.3%', '20%'],
        padding: '10px',
        '&.slider' : {
            //flex : '1 0 100%',
            maxWidth : '100%',
            height : '100%'
        }
    }),
    pageTile__small__wrapper: {
        boxShadow: '0.5px 1px 2px 0 rgba(0,0,0,.25)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: variables.colorLightGrey,
        transition:'all .2s'
    },
    pageTile__small__content: {
        '&:hover': {
            textDecoration: 'none',
            '.pageTile__small__bg': {
                transform: 'scale(1.05)'
            },
            '.pageTile__small__title' : {
                color : 'white'
            },
            '.pageTile__small__wrapper' : {
                backgroundColor : variables.colorLightGreen
            },
        }
    },
    pageTile__small__title: mq({
        fontSize: ['32px',null,'23px'],
        lineHeight: '1.1',
        padding: '0.5rem 0.5rem',
        //flex: '1 0 auto',
        color : variables.colorGreen,
        minHeight: '72px',
        fontWeight : '600',
        maxWidth : '84%'
    }),
    pageTile__small__bg: {
        paddingTop: '56.25%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        transition: 'all .2s'
    },
    pageTile__small__des: {
        fontSize: '18px',
        lineHeight: 1.2,
        padding: '0.5rem 0.5rem',
        color: variables.colorLightGrey,
        flex: '1 0 auto',
    },
    pageTile__small__button: {
        padding: '0rem 0.5rem 0.5rem',
        'button': {
            //backgroundColor: 'transparent',
            
            //color: variables.fontColor,
            //borderColor: variables.colorDarkGray,
            
            fontWeight: '600',
            padding : '14px 30px',
            fontSize : '18px',
            lineHeight: '1',
            letterSpacing: '1px'
        }
    }
}