/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./orderItemStyle";
import React from "react";

import { Button, ErrorMessage } from "../../../components";
import utils from "../../../utils";

import { CartSummary } from "../cartSummary/summary";

export function OrderItems({ cart, form, moveNext, post, remove }) {
  //const model = form.model;
  const items = form.findControl("items").controls;
  const render = (name, parent = null) => form.renderControl(name, parent);

  function itemAmount(item, orderItem) {
    const val = item.getValue();
    return (val.quantity * (orderItem.unitPrice * 100)) / 100;
  }

  const next = (e) => {
    form.validateForm(e, () => {
      moveNext();
    });
  };

  function ItemVariantSummary({ orderItem }) {
    if (!orderItem.variantOptionsSerialised) return null;
    const options = JSON.parse(orderItem.variantOptionsSerialised);
    return (
      <div>
        {options
          .map((x) => (
            <span>
              <span css={style.optionName}>{x.Name}:</span>
              <span css={style.optionValue}>{x.Value}</span>
            </span>
          ))
          .reduce(
            (acc, x) =>
              acc === null ? (
                x
              ) : (
                <React.Fragment>
                  {acc}, {x}
                </React.Fragment>
              ),
            null
          )}
      </div>
    );
  }

  const orderItems = cart.orderItems.filter((x) => x.orderItemType === 0);
  // const taxLines = cart.orderItems.filter((x) => x.orderItemType === 1);

  return (
    <div
      style={{
        padding: "2rem 1rem 2rem",
        paddingBottom: "0rem",
        backgroundColor: "rgba(255,255,255,0.5)",
      }}
    >
      <div className="row">
        <div className="col-md-9">
          <form css={style.items}>
            {orderItems.map((orderItem, index) => (
              <div key={orderItem.orderItemId} css={style.item}>
                <div className="row">
                  <div className="col-sm-2">
                    <img src={orderItem.thumbnailUrl} alt="immmm" />
                  </div>

                  <div className="col-sm-6">
                    <b
                      dangerouslySetInnerHTML={{ __html: orderItem.itemName }}
                    ></b>
                    <ItemVariantSummary orderItem={orderItem} />
                  </div>

                  <div className="col-sm-2">
                    Qty: {render("quantity", items[index])}
                  </div>

                  <div className="col-sm-2 remove__button" css={style.remove}>
                    <b>
                      {utils.money.formatMoney(
                        itemAmount(items[index], orderItem)
                      )}
                    </b>
                    <Button
                      btnStyle="outline-dark"
                      className="btn-sm"
                      onClick={(e) => remove(e, index)}
                      status={form.status}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            {/* {taxLines.map((orderItem, index) => (
              <div key={orderItem.orderItemId} css={style.item}>
                <div css={style.product}>Tax 111111: {orderItem.itemName}</div>
                <div css={style.details}>
                  <div css={style.qty}></div>
                  <div css={style.amount}>
                    <b>{utils.money.formatMoney(orderItem.unitPrice)}</b>
                  </div>
                  <div css={style.remove}></div>
                </div>
              </div>
            ))} */}

            <div css={style.item}>
              <div css={style.product}> GST: GST included in price </div>
            </div>

          </form>
        </div>
        <div className="col-md-3">
          <CartSummary cart={cart} />
        </div>
        <div className="col-md-9 form__actions" style={{ textAlign: "center" }}>
          <Button onClick={next} status={form.status}>
            Next
          </Button>
          <ErrorMessage
            errors={form.errors}
            summaryMessage="Please review the errors."
          />
        </div>
      </div>

      <div
        className="row order__items__c"
        style={{
          marginTop: "2rem",
          padding: "1rem",
          backgroundColor: "#464934",
          marginLeft: "-21px",
          marginRight: "-21px",
        }}
      >
        <div className="col-12 col-sm-5" style={{ textAlign: "center" }}>
          <img
            alt=""
            src="/sites/anfc/media/misc/seed-packets--stacked.png"
            width="100%"
            style={{ maxWidth: "340px" }}
          />
        </div>
        <div
          className="col-12 col-sm-7"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div>
            <h4 style={{ color: "rgb(218, 165, 33)" }}>
              <strong>Get a FREE Pack of Seeds With Every Purchase!</strong>
            </h4>
            <p style={{ paddingBottom: "0px", color: "white" }}>
              We aim to nurture the human spirit with full inclusion. With each
              order, you will receive a <strong>FREE</strong> gift as part of an
              initiative of Australian Native Food Co. 1 x Pack of Seeds Native
              seeds.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
