import React from 'react';
import { useRouter } from '../../components';

export function SearchBar({onCloseMenu}) {
  const [keyword, setKeyword ] = React.useState('')
  const { navigate } = useRouter();
  function search(e) {
    e.preventDefault();
    onCloseMenu();
    navigate('/search?q=' + encodeURIComponent(keyword))
  }
  function onChange(e) {
    setKeyword(e.target.value);
  }
  return (
    <form className="searchBar" onSubmit={(e) => search(e)}>
      <input type="text" className="searchBar__input" placeholder="SEARCH" value={keyword} onChange={onChange}></input>
    </form>);

}
